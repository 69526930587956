import React from "react";
import { useTranslation } from "react-i18next";
import HeadingText from "../molecules/HeadingText";
import WineNftContainer from "../molecules/WineNftsContainer";

export default function SectionWineNFT() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const wineNFTs = [
    "wineNFTs.card0",
    "wineNFTs.card1",
    "wineNFTs.card2",
    "wineNFTs.card3",
    "wineNFTs.card4",
    "wineNFTs.card5",
    "wineNFTs.card6",
    "wineNFTs.card7",
  ];

  return (
    <section
      className={`winenfts-section d-flex flex-column justify-content-center align-items-center`}
    >
      <HeadingText
        heading={t("wineNFTs.title")}
        paragraph={t("wineNFTs.subtitle")}
        textCenter
      />
      <WineNftContainer nfts={wineNFTs} />
    </section>
  );
}
