import React from "react";
import { useTranslation } from "react-i18next";

export default function BestSellerCardOutlined({
  img0,
  img1,
  alt0,
  alt1,
  text,
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div className="card-winenft card-best-seller d-flex flex-column align-items-center gap-3">
      <img
        className="card-best-seller__img"
        src={img0}
        alt={alt0}
        height="280px"
        width="150px"
      />
      <div className="card-best-seller__container d-flex justify-content-between align-items-center gap-3">
        <p className="card-best-seller__p">{t(text)}</p>
        <img
          className="card-best-seller__second-img"
          src={img1}
          alt={alt1}
          width="52px"
        />
      </div>
    </div>
  );
}
