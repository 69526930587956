import React from "react";

export default function WineNftCardOutlined({ text }) {
  return (
    <div className="card-winenft d-flex align-items-center gap-3">
      <svg
        className="card-winenft__icon"
        width="38"
        height="35"
        viewBox="0 0 38 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19 0L23.2658 13.1287H37.0701L25.9022 21.2426L30.1679 34.3713L19 26.2574L7.83208 34.3713L12.0978 21.2426L0.929926 13.1287H14.7342L19 0Z"
          fill="url(#paint0_linear_1103_129)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1103_129"
            x1="38"
            y1="19"
            x2="1.27184"
            y2="18.6353"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FC6964" />
            <stop offset="0.510417" stopColor="#F36E1E" />
            <stop offset="1" stopColor="#FCC442" />
          </linearGradient>
        </defs>
      </svg>
      <p className="card-winenft__text">{text}</p>
    </div>
  );
}
