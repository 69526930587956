import React from "react";
import { useTranslation } from "react-i18next";
import LandingForm from "../molecules/LandingForm";

export default function SectionSecondForm() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <section
      id={`second-form-section`}
      className={`second-form-section purple-gradient d-flex flex-column justify-content-center align-items-center`}
    >
      <h4 className={`revolution-section__h4 text-center`}>
        {t("secondForm.title")}
      </h4>
      <LandingForm
        textButton={t("firstForm.button")}
        onClick={null}
        primary
        classText={"title-heading__button"}
      />
    </section>
  );
}
