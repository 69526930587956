import React from "react";
import { useTranslation } from "react-i18next";
import HeadingText from "../molecules/HeadingText";
import LandingForm from "../molecules/LandingForm";

export default function SectionFirstForm() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <section
      id={`first-form-section`}
      className={`first-form-section d-flex flex-column flex-lg-row justify-content-center align-items-center gap-5`}
    >
      <HeadingText
        heading={t("firstForm.heading")}
        paragraph={t("firstForm.paragraph")}
      />
      <LandingForm
        textButton={t("firstForm.button")}
        onClick={null}
        primary
        classText={"title-heading__button"}
        end
      />
    </section>
  );
}
