import React from "react";
import { useTranslation } from "react-i18next";
import Winery0 from "../../assets/winery0.webp";
import Winery1 from "../../assets/winery1.webp";
import Winery2 from "../../assets/winery2.webp";
import Winery3 from "../../assets/winery3.webp";
import Winery4 from "../../assets/winery4.webp";

export default function SectionWinery() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const wineries = [
    { src: Winery0, alt: "wineries.img0", height: "101px" },
    { src: Winery1, alt: "wineries.img1", height: "142px" },
    { src: Winery2, alt: "wineries.img2", height: "48px" },
    { src: Winery3, alt: "wineries.img3", height: "78px" },
    { src: Winery4, alt: "wineries.img3", height: "61px" },
  ];

  return (
    <section
      className={`wineries-section purple-gradient d-flex flex-wrap justify-content-center align-items-center gap-5`}
    >
      {wineries.map((winery) => {
        return (
          <img
            key={winery.alt}
            className={`wineries-section__img`}
            src={winery.src}
            alt={t(winery.alt)}
            width="200px"
            height={winery.height}
            loading="lazy"
          />
        );
      })}
    </section>
  );
}
