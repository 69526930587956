import React from "react";
import { useTranslation } from "react-i18next";

export default function Header() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <header className={`header d-flex justify-content-end align-items-center`}>
      <p
        className={`header__p-language header__p-language--hovered text-gradient`}
        onClick={() => i18n.changeLanguage("es")}
      >
        ES
      </p>
      <p className={`header__p-language text-gradient`}>|</p>
      <p
        className={`header__p-language header__p-language--hovered text-gradient`}
        onClick={() => i18n.changeLanguage("en")}
      >
        EN
      </p>
    </header>
  );
}
