import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import LandingButton from "../atoms/LandingButton"; // Import the LandingButton component
import { postUserData } from "../../services/axiosService";

export default function LandingForm({
  textButton,
  primary,
  secondary,
  onClick,
  classText,
  type,
  end,
}) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const initialValues = {
    name: "",
    sex: "",
    birthdate: "",
    email: "",
  };

  const [requestState, setRequestState] = useState({
    isSubmitting: false,
    isSubmittedOk: false,
    errorMessage: null,
  });

  const validationSchema = Yup.object({
    name: Yup.string().required(t("firstForm.nameError")),
    sex: Yup.string().required(t("firstForm.genderError")),
    birthdate: Yup.string().required(t("firstForm.birthDateError")),
    email: Yup.string()
      .email(t("firstForm.emailError0"))
      .required(t("firstForm.emailError1")),
  });

  const handleSubmit = (values, actions) => {
    setRequestState({
      isSubmitting: true,
      isSubmittedOk: false,
      errorMessage: null,
    });
    postUserData(values)
      .then((response) => {
        if (response.status === 200) {
          setRequestState({
            isSubmitting: false,
            isSubmittedOk: true,
            errorMessage: null,
          });
        }
      })
      .catch((error) => {
        setRequestState({
          isSubmitting: false,
          isSubmittedOk: false,
          errorMessage: error.message,
        });
      })
      .finally(() => {
        actions.resetForm();
      });
  };

  return (
    <div className={`landing-form`}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          handleSubmit(values, actions);
        }}
      >
        {(props) => {
          const { touched, errors, isValid, dirty, setFieldTouched } = props;
          i18n.on("languageChanged", () => {
            Object.keys(errors).forEach((fieldName) => {
              setFieldTouched(fieldName);
            });
          });
          return (
            <Form
              className={`d-flex flex-column justify-content-center align-items-center`}
            >
              <div className={`w-100`}>
                <Field
                  className={`landing-form__input`}
                  type="text"
                  name="name"
                  placeholder={t("firstForm.name")}
                />
                {errors.name && touched.name ? (
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                ) : (
                  <div className="error-message"></div>
                )}
              </div>
              <div className={`w-100`}>
                <Field
                  className={`landing-form__input`}
                  component="select"
                  name="sex"
                >
                  <option value="" disabled>
                    {t("firstForm.gender")}
                  </option>
                  <option value="M">{t("firstForm.gender0")}</option>
                  <option value="F">{t("firstForm.gender1")}</option>
                  <option value="NB">{t("firstForm.gender2")}</option>
                  <option value="O">{t("firstForm.gender3")}</option>
                </Field>
                {errors.gender && touched.gender ? (
                  <ErrorMessage
                    name="sex"
                    component="div"
                    className="error-message"
                  />
                ) : (
                  <div className="error-message"></div>
                )}
              </div>
              <div className={`w-100`}>
                <Field
                  className={`landing-form__input`}
                  type="text"
                  name="birthdate"
                  placeholder={t("firstForm.birthDate")}
                />
                {errors.birthdate && touched.birthdate ? (
                  <ErrorMessage
                    name="birthdate"
                    component="div"
                    className="error-message"
                  />
                ) : (
                  <div className="error-message"></div>
                )}
              </div>
              <div className={`w-100`}>
                <Field
                  className={`landing-form__input`}
                  type="email"
                  name="email"
                  placeholder={t("firstForm.email")}
                />
                {errors.email && touched.email ? (
                  <ErrorMessage
                    name="email"
                    component="div"
                    className="error-message"
                  />
                ) : (
                  <div className="error-message"></div>
                )}
              </div>
              <div
                className={`w-100 d-flex justify-content-center justify-content-lg-${
                  end ? "end" : "center"
                } align-items-center`}
              >
                <LandingButton
                  textButton={textButton}
                  primary={primary}
                  secondary={secondary}
                  onClick={requestState.isSubmitting ? null : onClick}
                  classText={classText}
                  type="submit"
                  isDisabled={
                    !isValid ||
                    !dirty ||
                    Object.keys(errors).length > 0 ||
                    requestState.isSubmitting
                  }
                  spinner={requestState.isSubmitting}
                />
              </div>
              <div className="error-message"></div>
              <div
                className={`landing-form__message d-flex justify-content-center justify-content-lg-${
                  end ? "end" : "center"
                } align-items-center`}
              >
                <p
                  className={`landing-form__message-p ${
                    requestState.isSubmittedOk
                      ? "landing-form__message-p--success"
                      : requestState.errorMessage
                      ? "landing-form__message-p--error"
                      : ""
                  }`}
                >
                  {requestState.isSubmittedOk
                    ? t("firstForm.submittedOk")
                    : requestState.errorMessage
                    ? t("firstForm.requestError")
                    : null}
                </p>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
