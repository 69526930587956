import React from "react";
import { useTranslation } from "react-i18next";
import ImageManWithWine from "../../assets/manwine.webp";
import HeadingText from "../molecules/HeadingText";

export default function SectionBored() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleClick = () => {
    scrollToSection("second-form-section");
  };

  return (
    <section
      className={`bored-section d-flex flex-column justify-content-center align-items-center`}
    >
      <HeadingText
        heading={t("bored.heading")}
        paragraph={t("bored.paragraph")}
        button
        textButton={t("bored.button")}
        primary
        onClick={handleClick}
        classText={"title-heading__button"}
        image={{
          src: ImageManWithWine,
          alt: t("bored.img0"),
          height: "568px",
          width: "480px",
        }}
      />
    </section>
  );
}
