import React from "react";

export default function LandingButton({
  textButton,
  primary,
  secondary,
  onClick,
  classText,
  type,
  isDisabled,
  spinner,
}) {
  return (
    <button
      className={`button ${classText ? classText : ""} ${
        primary ? "button--primary" : ""
      } ${
        secondary ? "button--secondary" : ""
      } d-flex justify-content-center align-items-center`}
      onClick={onClick}
      disabled={isDisabled}
      type={type}
    >
      {spinner ? (
        <div
          className="button__spinner spinner-border text-light"
          role="status"
        ></div>
      ) : (
        textButton
      )}
    </button>
  );
}
