import React from "react";
import { useTranslation } from "react-i18next";
import WineNftCardOutlined from "../atoms/WineNftCardOutlined";

export default function WineNftsContainer({ nfts }) {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <div
      className={`winenft-container d-flex flex-wrap justify-content-center align-items-streetch gap-3`}
    >
      {nfts?.map((wineNFT) => (
        <WineNftCardOutlined key={wineNFT} text={t(wineNFT)} />
      ))}
    </div>
  );
}
