import React from "react";
import LandingButton from "../atoms/LandingButton";

export default function HeadingText({
  textButton,
  primary,
  secondary,
  onClick,
  classText,
  heading,
  paragraph,
  button,
  image,
  textCenter,
}) {
  const paragraphFormatted = paragraph?.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));

  return (
    <div
      className={`${
        textCenter ? "winenft-heading" : "title-heading"
      } d-flex flex-column-reverse flex-lg-row justify-content-center align-items-center gap-2 gap-lg-5`}
    >
      {image && (
        <img
          className={`title-heading__image d-none d-sm-block`}
          src={image.src}
          alt={image.alt}
          height={image.height || "auto"}
          width={image.width || "auto"}
        />
      )}
      <div
        className={`title-heading__text d-flex flex-column justify-content-center align-items-center ${
          textCenter ? "" : "align-items-lg-start"
        } gap-4`}
      >
        <h2 className={`text-center ${textCenter ? "" : "text-lg-start"}`}>
          {heading}
        </h2>
        {paragraph && (
          <p className={`text-center ${textCenter ? "" : "text-lg-start"}`}>
            {paragraphFormatted}
          </p>
        )}
        {button && (
          <LandingButton
            textButton={textButton}
            primary={primary}
            secondary={secondary}
            onClick={onClick}
            classText={classText}
          />
        )}
      </div>
    </div>
  );
}
