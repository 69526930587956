import React from "react";
import BestSellerCardOutlined from "../atoms/BestSellerCardOutlined";

export default function BestSellerContainer({ bestSellers }) {
  return (
    <div
      className={`winenft-container d-flex flex-wrap justify-content-center align-items-streetch gap-3`}
    >
      {bestSellers?.map((bestSeller) => (
        <BestSellerCardOutlined
          key={bestSeller.text}
          text={bestSeller.text}
          img0={bestSeller.src0}
          img1={bestSeller.src1}
          alt0={bestSeller.alt0}
          alt1={bestSeller.alt1}
        />
      ))}
    </div>
  );
}
