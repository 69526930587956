import axios from "axios";

export const postUserData = (userData) => {
  var config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `https://hook.us1.make.com/3tzxux77airkmzc6s9bzvy9ffq7fom75`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(userData),
  };

  return axios(config);
};
