import React from "react";
import { useTranslation } from "react-i18next";
import HeadingText from "../molecules/HeadingText";
import Bottle0 from "../../assets/bottle0.webp";
import Bottle1 from "../../assets/bottle1.webp";
import Bottle2 from "../../assets/bottle2.webp";
import Winery0 from "../../assets/winery0.webp";
import Winery1 from "../../assets/winery1.webp";
import Winery2 from "../../assets/winery2.webp";
import BestSellerContainer from "../molecules/BestSellerContainer";

export default function SectionBestSellers() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  const bestSellers = [
    {
      src0: Bottle0,
      alt0: "bestSellers.img0",
      src1: Winery1,
      alt1: "wineries.img0",
      text: "bestSellers.card0",
    },
    {
      src0: Bottle1,
      alt0: "bestSellers.img1",
      src1: Winery0,
      alt1: "wineries.img1",
      text: "bestSellers.card1",
    },
    {
      src0: Bottle2,
      alt0: "bestSellers.img2",
      src1: Winery2,
      alt1: "wineries.img2",
      text: "bestSellers.card2",
    },
  ];

  return (
    <section
      className={`best-sellers-section d-flex flex-column justify-content-center align-items-center`}
    >
      <HeadingText heading={t("bestSellers.title")} textCenter />
      <BestSellerContainer bestSellers={bestSellers} />
    </section>
  );
}
