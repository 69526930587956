import React from "react";
import { useTranslation } from "react-i18next";
import HeadingText from "../molecules/HeadingText";
import ImageMain from "../../assets/section_main_pic.webp";
import DrinksifyLogo from "../../assets/logo.webp";

export default function SectionMain() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  function scrollToSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  }

  const handleClick = () => {
    scrollToSection("first-form-section");
  };

  return (
    <section
      className={`main-section d-flex flex-column justify-content-center align-items-center`}
    >
      <div
        className={`title d-flex flex-column justify-content-center align-items-center gap-2`}
      >
        <h1>
          <img
            src={DrinksifyLogo}
            alt={"Drinksify"}
            height="61px"
            width="300px"
          />
        </h1>
        <p className={`title__p text-gradient`}>{t("main.subtitle")}</p>
      </div>
      <HeadingText
        heading={t("main.heading")}
        paragraph={t("main.paragraph")}
        button
        textButton={t("main.button")}
        primary
        onClick={handleClick}
        classText={"title-heading__button"}
        image={{
          src: ImageMain,
          alt: t("main.img0"),
          height: "480px",
          width: "480px",
        }}
      />
    </section>
  );
}
