import React from "react";
import { useTranslation } from "react-i18next";

export default function SectionRevolution() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation("global");

  return (
    <section
      className={`revolution-section purple-gradient d-flex justify-content-center align-items-center`}
    >
      <h4 className={`revolution-section__h4 text-center`}>
        {t("revolution.title")}
      </h4>
    </section>
  );
}
